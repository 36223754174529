@import url("https://fonts.googleapis.com/css2?family=Cairo&family=Roboto&display=swap");

html {
  font-family: "Cairo", sans-serif;
}

.filepond--item {
  /* width: calc(33.33% - 0.5em); */
  height: 100px;
  min-height: 80px;
  margin: 0.25em;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 0.5em;
  box-sizing: border-box;
  /* display: flex; */
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  position: relative;
  background: #fff;
}

.editor-description {
  border: solid 1px #c4c4c4;
  padding: 4px;
  border-radius: 5px;
}

.editor-description:hover {
  border: 1px solid black;
  border-radius: 5px;
}

.editor-description:focus-within {
  border: 2px solid #009bd0;
  border-radius: 5px;
}

.editor-title {
  border: solid 1px #c4c4c4;
  padding: 4px;
  border-radius: 5px;
}

.editor-title:hover {
  border: 1px solid black;
  border-radius: 5px;
}

.editor-title:focus-within {
  border: 2px solid #009bd0;
  border-radius: 5px;
}

.demo-editor {
  border: 1px solid #f1f1f1 !important;
  padding: 5px !important;
  border-radius: 2px !important;
  line-height: normal;
}

.container {
  max-width: 1170px;
  margin: auto;
}

img {
  max-width: 100%;
}

.post-details {
  padding: 30px 0;
  /* display: flex;
  justify-content: center;
  min-height: 100vh;
  width: 100%; */
}

.post-details iframe {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
}

.post-details .title {
  padding: 40px 0;
}

.post-details .title-content {
  border-bottom: 1px solid black;
  border-top: 1px solid black;
}

.post-details h4 {
  padding: 10px 0;
}

.post-details img {
  padding: 40px 0;
  width: 400px;
}

.post-details p {
  padding: 15px 0;
  color: initial;
  font-family: initial;
  line-height: initial;
  font-size: initial;
  margin-bottom: initial;
}
.col-12 {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}
